import React from "react";
// import { useAuth } from '../../providers/authProvider';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import SignIn from "./SignIn";
import SignUp from "./Signup";
import GoogleSignedIn from "../../components/GoogleSignedIn";

function AuthScreens() {
  // const authProvider = useAuth();

  //Just for testing
  // console.log(authProvider);

  return (
    <Router>
      <Switch>
        <Redirect exact from="/" to="/signin" />
        <Route exact path="/signin">
          <SignIn />
        </Route>
        <Route exact path="/signup">
          <SignUp />
        </Route>
        <Route exact path="/redirect">
          <GoogleSignedIn />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default AuthScreens;
