import { useState, useRef, useCallback, useEffect } from "react";
import { useUser } from "../../providers/userProvider";
import "@reach/combobox/styles.css";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  // InfoWindow,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import mapstyles from "../../assets/mapstyles";
import GreenButton from "../../components/GreenButton";

// const id = "74146b74c4f6fc36";
const libraries = ["places"];
const URL = "https://ip.nf/me.json";
const centerDefault = {
  lat: 29.3948,
  lng: 79.1265,
};
const mapContainerStyle = {
  height: "100%",
  width: "100%",
};
const options = {
  styles: mapstyles,
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeControl: true,
  mapTypeControlOptions: {
    mapTypeIds: ["roadmap", "satellite"],
  },
};

export function BusinessDetails({ businessName, setBusinessName }) {
  const [center, setCenter] = useState(null);
  const [marker, setMarker] = useState(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    id: "74146b74c4f6fc36",
  });

  useEffect(() => {
    fetch(URL, { method: "get" })
      .then((response) => response.json())
      .then((data) =>
        setCenter({ lat: data.ip.latitude, lng: data.ip.longitude })
      );
  }, []);

  const onMapClick = useCallback((e) => {
    setMarker({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
    // console.log(marker);
  }, []);

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  const { createNewBusiness } = useUser();
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [mobileNo, setMobileNo] = useState("");

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <form
      className="signAuthBody form"
      onSubmit={(e) => {
        e.preventDefault();
        createNewBusiness({
          name: businessName,
          number: mobileNo,
          marker,
          state,
          city,
          street,
          pinCode,
        });
      }}
    >
      <h2>Create Business</h2>
      <div>
        <label>Business Name*</label>
        <input
          autoComplete="new-password"
          required
          placeholder="My Business"
          value={businessName}
          onChange={(event) => setBusinessName(event.target.value)}
        />
        <label>Contact Number</label>
        <input
          placeholder="Mobile No."
          value={mobileNo}
          onChange={(event) => setMobileNo(event.target.value)}
        />
        <label>Location</label>
        <Search panTo={panTo} />
        <div
          style={{
            overflow: "hidden",
            borderRadius: "1rem",
            margin: "2rem 0",
            height: "40rem",
            // padding: 10,
            // background: "red",
          }}
        >
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={center ? 8 : 4}
            center={center || centerDefault}
            options={options}
            onClick={onMapClick}
            onLoad={onMapLoad}
          >
            {marker && (
              <Marker
                title={businessName}
                position={{ lat: marker.lat, lng: marker.lng }}
              />
            )}
          </GoogleMap>
        </div>
        <label>Display Address</label>
        <div>
          <input
            placeholder="State"
            value={state}
            onChange={(event) => setState(event.target.value)}
          />
          <input
            placeholder="city"
            value={city}
            onChange={(event) => setCity(event.target.value)}
          />
          <input
            placeholder="Street"
            value={street}
            onChange={(event) => setStreet(event.target.value)}
          />
          <input
            placeholder="Zip/Pin code"
            value={pinCode}
            onChange={(event) => setPinCode(event.target.value)}
          />
        </div>
      </div>
      <GreenButton classN="greenButton form" name="Create" />
    </form>
  );
}
function Search({ panTo }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 29.3948, lng: () => 79.1265 },
      radius: 100 * 1000,
    },
  });

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };

  // console.log(data);

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        autoComplete="new-password"
        placeholder="Address"
        value={value}
        disabled={!ready}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <ComboboxPopover className="popOver">
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
}
