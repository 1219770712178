import { useState, useEffect } from "react";
import AppForm from "../../components/AppForm";
import HomeMenu from "../../components/HomeMenu";
import ItemUi from "../../components/ItemUi";
import NavBar from "../../components/NavBar";
import ScreenNav from "../../components/ScreenNav";
import { useBusiness } from "../../providers/businessProvider";
import { useIsPc } from "../../providers/IsPcProvider";
import { useUser } from "../../providers/userProvider";

function ProductList() {
  const { userData } = useUser();
  const { productSearch } = useBusiness();
  const { isPc } = useIsPc();
  const [search, setSearch] = useState("");
  const [listItems, setListItems] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    productSearch(search)
      .then(
        (value) => {
          setListItems(value);
          setError(null);
        },
        (err) => {
          console.log(err.message);
          setError(err);
        }
      )
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className="appBody">
      {isPc ? (
        <NavBar name={userData.name} />
      ) : (
        <ScreenNav screenName="Products" />
      )}
      <div className="child">
        {isPc ? <HomeMenu /> : null}
        <div className="contentBody" style={{ overflow: "unset" }}>
          <div className="content form" style={{ flex: "unset" }}>
            <AppForm value={search} placeholder="Search" setValue={setSearch} />
          </div>
          <div style={{ overflow: "auto" }}>
            {loading ? (
              "loading"
            ) : error ? (
              error.message
            ) : (
              <ItemUi list={listItems} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

// function ItemUi({list}) {
//     return (
//         <ul className='listStyle'>
//             {list.map((item)=>
//             <li key={item._id}>
//                 {item.name}
//             </li>)}
//         </ul>
//     );
// }

export default ProductList;
