import React from 'react';

function BusinessTitle(props) {
    const {businessName, roll} = props;
    return (
        <div className='businessTitle'>
            <h2>{businessName}</h2>
            {roll && <h5>{roll}</h5>}
        </div>
    );
}

export default BusinessTitle;