import { useState } from "react";
import AppForm from "../../components/AppForm";
import GreenButton from "../../components/GreenButton";
import HomeMenu from "../../components/HomeMenu";
import NavBar from "../../components/NavBar";
import ProductList from "../../components/ProductList";
import ScreenNav from "../../components/ScreenNav";
import TwoIn1 from "../../components/TwoIn1";
import { useIsPc } from "../../providers/IsPcProvider";
import { useUser } from "../../providers/userProvider";

function AddCustomer() {
  const { userData } = useUser();
  const { isPc } = useIsPc();
  const [cName, SetCName] = useState("");

  return (
    <div className="appBody">
      {isPc ? (
        <NavBar name={userData.name} />
      ) : (
        <ScreenNav screenName="Add Customer" />
      )}
      <div className="child">
        {isPc ? <HomeMenu /> : null}
        <div className="contentBody">
          <form className="content form">
            <AppForm name="Customer Name" value={cName} setValue={SetCName} />
            <AppForm name="Mobile No." />
            <ProductList lable="Products Purchased" />
            <TwoIn1 name1="Amount Due" name2="Amount Paid" />
            <GreenButton classN="greenButton form" name="Add" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCustomer;
