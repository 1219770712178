import NavBar from "../../components/NavBar";
import { useUser } from "../../providers/userProvider";
import HomeMenu from "../../components/HomeMenu";

function Home() {
  const { userData } = useUser();

  return (
    <div className="appBody">
      <NavBar name={userData.name} />
      <HomeMenu />
    </div>
  );
}

export default Home;
