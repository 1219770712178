import NumberFormat from "react-number-format";
const INRformat = (value) =>
  value ? (
    <NumberFormat
      value={value}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"₹"}
      thousandsGroupStyle="lakh"
      renderText={(value) => value}
    />
  ) : (
    0
  );
export default INRformat;
