import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
// import Bill from "../../assets/bill";
import ScreenNav from "../../components/ScreenNav";
import { useBusiness } from "../../providers/businessProvider";
import "../../styles/css/print.css";
import { BSON } from "realm-web";
import INRformat from "../../assets/inrFormat";
import { billNoFormat } from "../../assets/billNoFormat";

function Print() {
  const { state } = useLocation();
  const { praseUrl } = useBusiness();
  const { bill, businessData, showSerialNo } = state;
  const componentRef = useRef();
  const [printing, setPrinting] = useState(true);
  const [logoUrl, setLogoUrl] = useState(null);

  // const logoUrl = async () => await praseUrl(businessData.logo);

  useEffect(() => {
    praseUrl(businessData.logo).then((data) => setLogoUrl(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessData.logo]);

  const handleGetContent = () => {
    setPrinting(true);
  };
  const handleReset = () => {
    setPrinting(false);
  };

  const handlePrint = useReactToPrint({
    documentTitle: `Bill#${billNoFormat(bill?.billNo)}(${businessData.name})`,
    // bodyClass: "pageContent topSection midSection footer",
    onBeforeGetContent: handleGetContent,
    onBeforePrint: handleReset,
    // pageStyle: `@page {
    //   size: A4;
    //   backgroundGraphics: true;
    // }`,
    content: () => componentRef.current,
  });

  // console.log(bill);
  const cgst = INRformat(bill?.taxAmount / 2);
  const gst = INRformat(bill?.taxAmount);

  return (
    <div className="appBody">
      <ScreenNav screenName="Print bill" />
      <div className="child">
        <div>
          <button style={{ margin: "1rem" }} onClick={handlePrint}>
            Print out!
          </button>
        </div>

        <div className="contentBody">
          <div
            style={{
              display: "grid",
              placeItems: "center",
              overflow: "auto",
              padding: 10,
            }}
          >
            <div
              style={{
                overflow: "unset",
                width: "210mm", //printing ? "210mm" : "100%",
                height: "297mm",
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.349)",
              }}
            >
              <div ref={componentRef} className="pageContent">
                {/* {JSON.stringify(bill)} */}
                <div className="topSection">
                  <div className="billHeader">
                    <h1>BILL</h1>{" "}
                    <table>
                      <tbody>
                        <tr>
                          <td>Date</td>
                          <td>
                            {bill?._id
                              ? new BSON.ObjectId(bill._id)
                                  .getTimestamp()
                                  .toLocaleDateString()
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>Bill no</td>
                          <td>{billNoFormat(bill?.billNo)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="billInfo">
                    <div className="companyDetails">
                      <>
                        <img alt="logo" src={logoUrl} />
                        <h2>{businessData.name || "Business Name"}</h2>
                        <h3>
                          {businessData?.address?.street ? (
                            <>
                              {businessData.address.street}
                              <br />
                            </>
                          ) : null}
                          {businessData?.address?.street2 ? (
                            <>
                              {businessData.address.street2}
                              <br />
                            </>
                          ) : null}
                          {!businessData?.address?.city &&
                          !businessData?.address?.pinCode ? null : (
                            <>
                              {(businessData?.address?.city || "") +
                                ` (${businessData?.address?.pinCode || ""})`}
                              <br />
                            </>
                          )}
                          {businessData?.address?.state}
                        </h3>
                      </>
                      <>
                        {businessData?.gstNo && (
                          <h3 className="gstNo">
                            GST No : {businessData.gstNo}
                          </h3>
                        )}
                      </>
                    </div>
                    <div className="customerDetails">
                      <>
                        <span>
                          To
                          <br />
                        </span>
                        <div>
                          <span>
                            <h3>{bill?.customer?.name}</h3>
                            {bill?.customer?.address?.street ? (
                              <>
                                {bill?.customer?.address?.street}
                                <br />
                              </>
                            ) : null}
                            {bill?.customer?.address?.street2 ? (
                              <>
                                {bill?.customer?.address?.street2}
                                <br />
                              </>
                            ) : null}
                            {bill?.customer?.address?.landmark ? (
                              <>
                                {bill?.customer?.address?.landmark}
                                <br />
                              </>
                            ) : null}
                            {!bill?.customer?.address?.city &&
                            !bill?.customer?.address?.pincode ? null : (
                              <>
                                {bill?.customer?.address?.city +
                                  ` (${bill?.customer?.address?.pincode})`}
                                <br />
                              </>
                            )}
                            {bill?.customer?.mobileNumbers?.length ? (
                              <>
                                {`Contact No. ${bill?.customer?.mobileNumbers.join(
                                  ", "
                                )}`}
                                <br />
                              </>
                            ) : null}
                          </span>
                        </div>
                      </>
                      <>
                        <h2>Bill Amount : {INRformat(bill?.totalAmount)}</h2>

                        {bill?.dueAmount ? (
                          <>
                            <h2>Paid : {INRformat(bill?.amountPaid)}</h2>
                            <h2>Due Amount : {INRformat(bill?.dueAmount)}</h2>
                          </>
                        ) : (
                          <h2>Paid</h2>
                        )}
                      </>
                    </div>
                  </div>
                </div>
                {/* {JSON.stringify(bill.products)} */}
                <div className="midSection">
                  <table>
                    <thead>
                      <tr>
                        <th>ITEM</th>
                        {showSerialNo && <th>SERIAL NO</th>}
                        <th>GST</th>
                        <th>QTY</th>
                        <th>PRICE</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bill.products.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.name || ""}</td>
                          {showSerialNo && <td>{item.serialNo.join(", ")}</td>}
                          <td>{(item?.taxPercent || "") + "%"}</td>
                          <td>{item?.billQty || ""}</td>
                          <td>{INRformat(item?.price)}</td>
                          <td>
                            {INRformat(
                              (item?.billQty || 0) * (item?.price || 0)
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr
                        // className={"extraRow"}
                        style={{
                          borderBottom: "unset",
                          backgroundColor: "unset",
                        }}
                      >
                        <td />
                        {showSerialNo && <td />}
                        <td />
                        <td />
                        <td>
                          Taxes(
                          {Math.round(
                            (bill?.taxAmount / bill?.totalAmount) * 100 || 0
                          )}
                          %)
                        </td>
                        <td>
                          SGST: {cgst}
                          <br />
                          CGST: {cgst}
                        </td>
                      </tr>
                      <tr
                        className={"extraRow"}
                        style={{ backgroundColor: "unset" }}
                      >
                        <td />
                        {showSerialNo && <td />}
                        <td />
                        <td />
                        <td />
                        <td>GST: {gst}</td>
                      </tr>
                      {bill?.discount ? (
                        <tr
                          className={"extraRow"}
                          style={{ backgroundColor: "unset" }}
                        >
                          <td />
                          {showSerialNo && <td />}
                          <td />
                          <td />
                          <td>Discount</td>
                          <td>- {INRformat(bill?.discount)}</td>
                        </tr>
                      ) : null}
                      <tr
                        className={"extraRow"}
                        style={{ backgroundColor: "unset" }}
                      >
                        <td />
                        {showSerialNo && <td />}
                        <td />
                        <td />
                        <td>Grand Total</td>
                        <td>{INRformat(bill?.totalAmount)}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className="bottomSection">
                  <div>{bill?.info || "Thank you"}</div>
                  <div />
                  <div>
                    Have any Question?
                    <br />
                    Contact us at{" - "}
                    {Object.values(
                      businessData?.contactinfo || { default: "N/A" }
                    ).join(", ")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Print;
