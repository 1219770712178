import BusinessTitle from "./BusinessTitle";
import NavigationButton from "./NavigationButton";
import { BsArrowRight } from "react-icons/bs";
import BottomCard from "./BottomCard";
import { useBusiness } from "../providers/businessProvider";
import { useIsPc } from "../providers/IsPcProvider";
import { useUser } from "../providers/userProvider";
import { capitalizeFirstLetter } from "../assets/capatalizeFirst";

function HomeMenu() {
  const { businessData } = useBusiness();
  const { isPc } = useIsPc();
  const businessIndex = 0;
  const { userData } = useUser();

  return (
    <div className={isPc ? "contentBody pc" : "contentBody"}>
      <div className="content">
        <BusinessTitle
          businessName={capitalizeFirstLetter(businessData.name)}
          roll={capitalizeFirstLetter(
            userData.businesses[businessIndex]?.level
          )}
        />
        <NavigationButton name={"Create bill"} to={"/createBill"} />
        <NavigationButton name={"Add Product"} to={"/addProduct"} />
        <NavigationButton name={"Add Receipt"} to={"/addReceipt"} />
        <NavigationButton name={"Add Customer"} to={"/addCustomer"} />
      </div>
      <footer className={isPc ? "footer" : "footer fixed"}>
        <span>
          View all <BsArrowRight className="icon" style={{ width: 20 }} />
        </span>
        <BottomCard
          name={"Bills"}
          number={businessData?.billInfo?.totalBills || 0}
          to={"/billList"}
        />
        <BottomCard
          name={"Products"}
          number={businessData.products?.length || 0}
          to={"/productList"}
        />
      </footer>
    </div>
  );
}

export default HomeMenu;
