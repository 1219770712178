import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { Link, useHistory } from "react-router-dom";

function ScreenNav({ screenName }) {
  let history = useHistory();
  return (
    <nav className="navBar">
      <div
        // replace
        className="iconLink"
        onClick={() => history.goBack()}
        // to="/"
      >
        <BsArrowLeft className="icon" />
      </div>
      <h2>{screenName}</h2>
      <div className="icon" />
    </nav>
  );
}

export default ScreenNav;
