import { createContext, useContext, useEffect, useState } from "react";
import { BSON } from "realm-web";
import { useAuth } from "./authProvider";
import { useUser } from "./userProvider";
// import googleOneTap from 'google-one-tap';

const BusinessContext = createContext();

export const BusinessProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const { userData } = useUser();
  const businessId = userData.businesses[0].id;
  const [businessData, setBusinessData] = useState({ name: "My Business" });
  const [businessLoading, setBusinessLoading] = useState(true);

  function getBusinessData() {
    setBusinessLoading(true);
    const mongodb = currentUser.mongoClient("mongodb-atlas");
    const BusinessCollection = mongodb.db("busivisy").collection("businesses");

    BusinessCollection.findOne({
      _id: businessId,
    })
      .then(
        (value) => {
          if (!value) {
            console.log("failed, Business data can't be accessed");
          } else {
            setBusinessData(value);
          }
        },
        (error) => {
          console.log(error.message);
        }
      )
      .finally(() => setBusinessLoading(false));
  }

  useEffect(() => {
    // console.log(currentUser.id);
    if (!userData) {
      return;
    } else if (!userData.businesses) {
      return;
    } else if (userData.businesses.length < 1) {
      return;
    }
    getBusinessData();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, userData]);

  async function addProduct({
    productName,
    serialNoList = [],
    mrp,
    purchasePrice,
    qty,
    tax,
    info,
    image,
  }) {
    mrp = Number(mrp);
    purchasePrice = Number(purchasePrice);
    qty = Number(qty);

    if (!productName) {
      alert("Product Name doesnot exist");
      return;
    }
    if (qty < 0) {
      alert("Quantity not vaid");
      return;
    }

    const mongodb = currentUser.mongoClient("mongodb-atlas");
    const productsCollection = mongodb.db("busivisy").collection("products");

    try {
      if (image) {
        var img = image.split(",");
        image = img[1];
        var type = img[0].split(";")[0].split(":")[1];
        var typ = type.split("/")[1];
        var fileName =
          `businesses/${businessData._id}/` + productName + "." + typ;

        await currentUser.functions.uploadImg(
          image,
          `busivisy`,
          fileName,
          type
        );
      }
      let product = {
        businessId: businessData._id,
        name: productName,
        serialNos: serialNoList.filter((el) => el != null),
        mrp: mrp || 0,
        purchasePrice: purchasePrice || 0,
        qty: qty || 0,
        tax: tax,
        info: info,
        image: fileName && [fileName],
      };
      let result = await productsCollection.insertOne(product);
      getBusinessData();
      alert("Product Added");
      product = { ...product, _id: result.insertedId };
      return product;
    } catch (error) {
      alert(error.error);
      throw Error("Insert failed");
    }
  }

  async function productSearch(search) {
    // TODO! infinity scroll
    const mongodb = currentUser.mongoClient("mongodb-atlas");
    const productCollection = mongodb.db("busivisy").collection("products");

    if (search === "")
      return await productCollection.aggregate([
        // { $limit: 20 },
        { $project: { name: 1, qty: 1, mrp: 1, tax: 1 } },
      ]);
    else
      return await productCollection.aggregate([
        {
          $search: {
            autocomplete: {
              path: "name",
              query: search,
            },
          },
        },
        // { $limit: 20 },
        { $project: { name: 1, qty: 1, mrp: 1, tax: 1 } },
      ]);
  }

  async function customerSearch({ search = "" }) {
    // TODO! infinity scroll
    const mongodb = currentUser.mongoClient("mongodb-atlas");
    const productCollection = mongodb.db("busivisy").collection("customers");

    if (search.length < 2) return await productCollection.find();
    else
      return await productCollection.aggregate([
        {
          $search: {
            autocomplete: {
              path: "name",
              query: search,
            },
          },
        },
        { $limit: 20 },
        { $project: { name: 1 } },
      ]);
  }

  async function billSearch(search) {
    // TODO! infinity scroll
    const mongodb = currentUser.mongoClient("mongodb-atlas");
    const billCollection = mongodb.db("busivisy").collection("bills");

    if (search === "")
      return await billCollection.aggregate([
        // { $limit: 20 },
        { $project: { "customer.name": 1, customerName: 1, billNo: 1 } },
      ]);
    else
      return await billCollection.aggregate([
        {
          $search: {
            autocomplete: {
              path: "customer.name",
              query: search,
            },
          },
        },
        // { $limit: 20 },
        { $project: { "customer.name": 1, customerName: 1, billNo: 1 } },
      ]);
  }

  async function getBill(billId) {
    const mongodb = currentUser.mongoClient("mongodb-atlas");
    const productCollection = mongodb.db("busivisy").collection("bills");

    //get bill Data=>
    var billData = await productCollection.findOne({
      _id: new BSON.ObjectId(billId),
    });

    return billData;
  }

  async function praseUrl(value) {
    if (value) {
      // getBusinessData();
      var result = await currentUser.functions.presignUrl(`busivisy`, value);
      console.log(`image url is  === ${result}`);
      return result;
    }
  }

  async function getProduct(productId) {
    const mongodb = currentUser.mongoClient("mongodb-atlas");
    const productCollection = mongodb.db("busivisy").collection("products");

    //get Product Data=>
    var productData = await productCollection.findOne({
      _id: new BSON.ObjectId(productId),
    });

    //get Images =>
    if (productData?.image && productData?.image[0]) {
      var img = [];
      await Promise.all(
        productData.image.map(async (fileName, index) => {
          var result = await currentUser.functions.presignUrl(
            `busivisy`,
            fileName
          );
          img[index] = result;
        })
      );
      console.log(img[0]);
      productData.image = img;
    }

    return productData;
  }
  // new Blob(img[0].Body.buffer)

  async function findOneProduct(find) {
    const mongodb = currentUser.mongoClient("mongodb-atlas");
    const productCollection = mongodb.db("busivisy").collection("products");

    return await productCollection.findOne(find);
  }

  async function createBill({
    selectedProducts,
    discount,
    taxAmount,
    total,
    paidAmount,
    dueAmount,
    customerName,
    mobileNumbers,
    address,
    info,
  }) {
    const mongodb = currentUser.mongoClient("mongodb-atlas");
    const billsCollection = mongodb.db("busivisy").collection("bills");
    taxAmount = Number(taxAmount);
    discount = Number(discount);
    total = Number(total);
    paidAmount = Number(paidAmount);
    dueAmount = Number(dueAmount);

    getBusinessData(); //to get teh latest bill no

    let bill = {
      businessId: businessData._id,
      billNo: businessData?.billInfo?.billNo
        ? businessData?.billInfo?.billNo + 1
        : undefined,
      customer: {
        name: customerName,
        mobileNumbers: mobileNumbers,
        address: address,
      },
      products: selectedProducts,
      totalAmount: total || 0,
      taxAmount: taxAmount || 0,
      discount: discount || 0,
      amountPaid: paidAmount || 0,
      dueAmount: dueAmount || 0,
      info: info,
    };

    try {
      let { insertedId } = await billsCollection.insertOne(bill);
      getBusinessData();
      // console.log(insertedId);
      if (insertedId) {
        bill = {
          ...bill,
          businessId: bill.businessId.toString(),
          _id: insertedId.toString(),
        };
        return window.confirm(`Bill created. Would you like to print it?`)
          ? bill
          : false;
      } else throw Error("no result");
      // return result;
    } catch (error) {
      alert(error.message);
      throw Error("Insert failed");
    }
  }

  function addReceipt({ vendorName, productList }) {
    console.log(vendorName, productList);
    //todo
  }

  function updateBusinessName(newbusinessName) {
    console.log(newbusinessName);
    //todo
  }

  function addCustomer({
    customerName,
    mobileNumber,
    emailAddress,
    street,
    city,
    pincode,
    landmark,
    amountPaid,
    amountDue,
  }) {
    console.log(
      customerName,
      mobileNumber,
      emailAddress,
      street,
      city,
      pincode,
      landmark,
      amountPaid,
      amountDue
    );
    //todo
  }

  function deleteProduct(productId) {
    console.log(productId);
    //todo
  }

  function deleteBill(billId) {
    console.log(billId);
    //todo
  }

  const wrapped = {
    businessData,
    businessLoading,
    getBusinessData,
    createBill,
    addProduct,
    addReceipt,
    updateBusinessName,
    addCustomer,
    deleteProduct,
    deleteBill,
    productSearch,
    customerSearch,
    billSearch,
    getBill,
    findOneProduct,
    getProduct,
    praseUrl,
  };

  return (
    <BusinessContext.Provider value={wrapped}>
      {children}
    </BusinessContext.Provider>
  );
};

export const useBusiness = () => {
  const business = useContext(BusinessContext);
  if (!business) {
    throw new Error(
      `You must call useBusiness() inside of a <BusinessProvider />`
    );
  }
  return business;
};
