import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./authProvider";
// import googleOneTap from 'google-one-tap';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState({ name: "Loading" });
  const [userLoading, setUserLoading] = useState(true);

  function getUserData() {
    setUserLoading(true);
    const mongodb = currentUser.mongoClient("mongodb-atlas");
    const usersCollection = mongodb.db("busivisy").collection("users");

    usersCollection
      .findOne({ _id: currentUser.id })
      .then(
        (value) => {
          if (!value) {
            // console.log('userdata value was null');
          } else {
            setUserData(value);
            // console.log('===============userdata set==============');
          }
        },
        (error) => {
          setUserData(null);
          console.log(error.message);
        }
      )
      .finally(() => setUserLoading(false));
  }

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    getUserData();

    return () => {
      // cleanup function
      // setUserData(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  async function createNewBusiness({
    name,
    number,
    state,
    city,
    street,
    pinCode,
    marker,
  }) {
    let businessId = await currentUser.functions.createNewBusinessDoc(name);
    const mongodb = currentUser.mongoClient("mongodb-atlas");
    const BusinessCollection = mongodb.db("busivisy").collection("businesses");
    BusinessCollection.updateOne(
      { _id: businessId },
      {
        $set: {
          geoLocaion: {
            type: "Point",
            coordinates: [marker.lng, marker.lat],
          },
          contactinfo: [{ mobileNo: number }],
          address: {
            state: state,
            city: city,
            street: street,
            pinCode: pinCode,
          },
        },
      }
    ).then(
      () => {
        console.log("New Business Created");
      },
      (error) => console.log("Business Creation Failed", error)
    );
    getUserData();
  }

  const wrapped = {
    userData,
    userLoading,
    getUserData,
    createNewBusiness,
  };

  return (
    <UserContext.Provider value={wrapped}>{children}</UserContext.Provider>
  );
};

export const useUser = () => {
  const userProvider = useContext(UserContext);
  if (!userProvider) {
    throw new Error(`You must call useUser() inside of a <UserProvider />`);
  }
  return userProvider;
};
