import React, { useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";
import GreenButton from "../../components/GreenButton";
import NavBar from "../../components/NavBar";
import { useUser } from "../../providers/userProvider";
import { BusinessDetails } from "./AddBusinessDetails";

function CreateBusiness() {
  //   const { logOut } = useAuth();
  const { userData } = useUser();
  const [businessName, setBusinessName] = useState("");

  return (
    // <div className='appBody'>
    <Router>
      <NavBar name={userData.name} />
      {/* <button onClick={logOut}>Log Out</button> */}
      <div style={{ overflow: "auto", height: "93%" }}>
        <Switch>
          {/* <Redirect exact from='/' to='/AddBusiness' /> */}
          <Route exact path="/">
            <AddOrCreate
              businessName={businessName}
              setBusinessName={setBusinessName}
            />
          </Route>
          <Route exact path="/createBusiness">
            <BusinessDetails
              businessName={businessName}
              setBusinessName={setBusinessName}
            />
          </Route>
          <Route exact path="/addBusiness">
            <AddBusiness />
          </Route>
          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
    // </div>
  );
}

export default CreateBusiness;

function AddOrCreate({ businessName, setBusinessName }) {
  return (
    <div className="signAuthBody">
      <h2>Create a new business:</h2>
      <input
        placeholder="Business Name"
        value={businessName}
        onChange={(event) => setBusinessName(event.target.value)}
      />
      <Link to="/createBusiness">
        <GreenButton name="Create" />
      </Link>
      <h3 style={{ padding: "0 1rem" }}>
        Have a Business invite code?
        <Link to="/addBusiness">Add existing Business</Link>
      </h3>
    </div>
  );
}

function AddBusiness() {
  const [code, setCode] = useState();

  return (
    <form
      className="signAuthBody"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <h2>Add existing business:</h2>
      <input
        placeholder="Invite Code"
        value={code}
        onChange={(event) => setCode(event.target.value)}
      />
      <GreenButton name="Add" />
      {/* <h3 style={{padding: '0 10vw'}}>
        Dont want to add existing business?
        <Link to='/'>Create new Business</Link>
    </h3> */}
    </form>
  );
}
