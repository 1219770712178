import AppForm from "./AppForm";
import { truncate } from "./truncate";
import { useState, useEffect } from "react";
import { BsX } from "react-icons/bs";
import { BsCheck } from "react-icons/bs";
import { useBusiness } from "../providers/businessProvider";
import GreenButton from "./GreenButton";
import { BsArrowLeft } from "react-icons/bs";
import TwoIn1 from "./TwoIn1";
import MiniAddProducts from "../views/main/MiniAddProducts";

function AddPopUp({ selectedProducts, setSelectedProducts, setShowPopUp }) {
  const [serialNo, setSerialNo] = useState([""]);
  const { productSearch } = useBusiness();
  const [search, setSearch] = useState("");
  const [allProductList, setAllProductList] = useState([]);
  const [showProducts, setShowProducts] = useState(true);
  const [focusedItem, setFocusedItem] = useState(null);
  const [billQty, setBillQty] = useState("");

  const reset = () => {
    setFocusedItem(null);
    setBillQty("");
    setSerialNo([""]);
  };

  const handleAddMoreSerial = () => {
    if (serialNo.length < billQty) {
      let arr = serialNo.slice();
      arr.push("");
      setSerialNo(arr);
    } else {
      alert(
        `Cannot add Serial No more then Quantity, that is ${billQty || 0}.`
      );
    }
  };

  const serialSplice = (index) => {
    let arr = serialNo.slice();
    arr.splice(index, 1);
    setSerialNo(arr);
  };

  useEffect(() => {
    if (serialNo.length > billQty && billQty > 0) {
      let diff = serialNo.length - billQty;
      let arr = serialNo.slice();
      arr.splice(billQty, diff);
      setSerialNo(arr);
    }
  }, [billQty, serialNo]);

  useEffect(() => {
    productSearch(search).then(
      (value) => {
        setAllProductList(value);
      },
      (err) => {
        console.log(err.message);
        alert(JSON.stringify(err.message));
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleAdd = () => {
    if (focusedItem.qty < 1) {
      alert("0 item in stock");
    } else {
      let serialNoFiltered = serialNo.filter((el) => el !== "" && el != null);
      if (
        !selectedProducts.some((product, index, arr) => {
          if (product.productId.toString() === focusedItem._id.toString()) {
            let finalBillQty = Number(billQty || 1) + Number(product.billQty);
            if (finalBillQty > focusedItem.qty) {
              alert(
                `Cannot add more then In stock. (${finalBillQty}>${focusedItem.qty})`
              );
            } else {
              let ar = arr.slice();
              ar[index] = {
                ...product,
                billQty: finalBillQty,
                serialNo:
                  serialNoFiltered?.length || product?.serialNo?.length
                    ? [...(product?.serialNo || undefined), ...serialNoFiltered]
                    : undefined,
              };
              setSelectedProducts(ar);
            }
            return true;
          } else {
            return false;
          }
        })
      ) {
        setSelectedProducts([
          ...selectedProducts,
          {
            productId: focusedItem._id,
            name: focusedItem.name,
            taxPercent: focusedItem.tax,
            billQty: Number(billQty || 1),
            price: focusedItem.mrp,
            serialNo: serialNoFiltered.length ? serialNoFiltered : undefined,
          },
        ]);
      }
    }
  };

  return (
    <div className="popUp">
      <nav className="navBar" style={{ height: "10%" }}>
        {focusedItem ? (
          <div onClick={reset} className="iconLink">
            <BsArrowLeft className="icon" />
          </div>
        ) : (
          <div onClick={() => setShowPopUp(false)} className="iconLink">
            <BsX className="icon" />
          </div>
        )}
        <h2>
          {!focusedItem
            ? showProducts
              ? "Search Products"
              : "Add Product"
            : focusedItem?.name || ""}
        </h2>
        {showProducts ? (
          <button
            className="addButton"
            style={{ margin: "0 10px" }}
            onClick={() => setShowProducts(false)}
          >
            Add New
          </button>
        ) : (
          <button
            className="addButton"
            style={{ margin: "0 10px" }}
            onClick={() => setShowProducts(true)}
          >
            Search
          </button>
        )}
      </nav>
      <div className="contentBody" style={{ maxHeight: "90%" }}>
        {showProducts ? (
          focusedItem ? (
            <form
              className="content"
              onSubmit={(e) => {
                e.preventDefault();
                handleAdd();
                reset();
              }}
            >
              {/* {JSON.stringify(focusedItem)} */}
              {!!focusedItem.name && (
                <AppForm
                  name="Produvt Name"
                  value={focusedItem.name}
                  readOnly
                />
              )}
              <TwoIn1
                placeholder1=" - "
                placeholder2="0.00"
                type1="number"
                type2="number"
                name1={"Price"}
                value1={focusedItem.mrp}
                name2={"GST"}
                value2={focusedItem.tax}
                // setValue2={}
                readOnly1
                readOnly2
                useNumberFormat1
                useNumberFormat2
                prefix1={"₹"}
                isPercent2
                // prefix2={"₹"}
                // thousandsGroupStyle1="lakh"
                // thousandSeparator1
                // thousandsGroupStyle2="lakh"
                // thousandSeparator2
              />
              {/* {focusedItem.mrp && (
                <AppForm
                  name="Price"
                  value={focusedItem.mrp}
                  type="number"
                  readOnly
                  useNumberFormat
                  prefix="₹"
                  thousandsGroupStyle="lakh"
                  thousandSeparator
                />
              )}
              {focusedItem.tax && (
                <AppForm
                  name="GST"
                  value={focusedItem.tax}
                  type="number"
                  readOnly
                  useNumberFormat
                  isPercent
                  // thousandsGroupStyle="lakh"
                  // thousandSeparator
                />
              )} */}
              {!!focusedItem.qty && (
                <AppForm name="In Stock" value={focusedItem.qty} readOnly />
              )}
              <AppForm
                name="Add quantity"
                value={billQty}
                setValue={(value) => {
                  if (value <= focusedItem.qty) setBillQty(value);
                }}
                placeholder="1"
              />
              <div
                className={serialNo.length > 1 ? "appForm numbers" : "appForm"}
              >
                <label>Add Serial No.</label>
                {serialNo.map((value, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <input
                      style={{ flex: 1 }}
                      value={value}
                      onChange={(e) => {
                        let arr = serialNo.slice();
                        arr[index] = e.target.value;
                        setSerialNo(arr);
                      }}
                    />
                    {serialNo.length > 1 && (
                      <div
                        onClick={() => serialSplice(index)}
                        style={{ display: "flex" }}
                      >
                        <BsX style={{ margin: "auto" }} />
                      </div>
                    )}
                  </div>
                ))}
                <div onClick={handleAddMoreSerial} className="addButton">
                  Add more
                </div>
              </div>
              <GreenButton classN="greenButton form" name="Add" />
            </form>
          ) : (
            <>
              <div className="content">
                <AppForm
                  placeholder="Search"
                  value={search}
                  setValue={setSearch}
                />
              </div>
              <div style={{ overflowY: "auto" }}>
                <ul className="listStyle">
                  {selectedProducts.map((item, index) => (
                    <li
                      // onClick={() => setFocusedItem(item)}
                      key={`selected:${index}`}
                      style={{ background: "rgba(0, 0, 0, 0.1)" }}
                    >
                      {
                        <>
                          <div style={{ flex: 1 }}>{truncate(item.name)}</div>
                          <div className="iconLink">
                            <BsCheck className="icon" />
                            {(item.billQty || 0) > 1 ? item.billQty : null}
                          </div>
                        </>
                      }
                    </li>
                  ))}
                  {allProductList.map((item, index) => (
                    <li onClick={() => setFocusedItem(item)} key={index}>
                      {
                        <>
                          <div style={{ flex: 1 }}>{truncate(item.name)}</div>
                          <div className="iconLink">
                            {/* <BsCheck className="icon" /> */}
                            {item.qty || 0}
                          </div>
                        </>
                      }
                    </li>
                  ))}
                </ul>
              </div>
              <GreenButton
                onClick={() => setShowPopUp(false)}
                style={{
                  alignSelf: "center",
                  position: "absolute",
                  bottom: 0,
                  margin: 10,
                }}
                name="Done"
              />
            </>
          )
        ) : focusedItem ? (
          <form
            className="content"
            onSubmit={(e) => {
              e.preventDefault();
              handleAdd();
              reset();
            }}
          >
            {/* {JSON.stringify(focusedItem)} */}
            {!!focusedItem.name && (
              <AppForm name="Produvt Name" value={focusedItem.name} readOnly />
            )}
            <TwoIn1
              placeholder1=" - "
              placeholder2="0.00"
              type1="number"
              type2="number"
              name1={"Price"}
              value1={focusedItem.mrp}
              name2={"GST"}
              value2={focusedItem.tax}
              // setValue2={}
              readOnly1
              readOnly2
              useNumberFormat1
              useNumberFormat2
              prefix1={"₹"}
              isPercent2
              // prefix2={"₹"}
              // thousandsGroupStyle1="lakh"
              // thousandSeparator1
              // thousandsGroupStyle2="lakh"
              // thousandSeparator2
            />
            {/* {focusedItem.mrp && (
                <AppForm
                  name="Price"
                  value={focusedItem.mrp}
                  type="number"
                  readOnly
                  useNumberFormat
                  prefix="₹"
                  thousandsGroupStyle="lakh"
                  thousandSeparator
                />
              )}
              {focusedItem.tax && (
                <AppForm
                  name="GST"
                  value={focusedItem.tax}
                  type="number"
                  readOnly
                  useNumberFormat
                  isPercent
                  // thousandsGroupStyle="lakh"
                  // thousandSeparator
                />
              )} */}
            {!!focusedItem.qty && (
              <AppForm name="In Stock" value={focusedItem.qty} readOnly />
            )}
            <AppForm
              name="Add quantity"
              value={billQty}
              setValue={(value) => {
                if (value <= focusedItem.qty) setBillQty(value);
              }}
              placeholder="1"
            />
            <div
              className={serialNo.length > 1 ? "appForm numbers" : "appForm"}
            >
              <label>Add Serial No.</label>
              {serialNo.map((value, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <input
                    style={{ flex: 1 }}
                    value={value}
                    onChange={(e) => {
                      console.log(e ? "yes" : "no");
                      let arr = serialNo.slice();
                      arr[index] = e.target.value;
                      setSerialNo(arr);
                    }}
                  />
                  {serialNo.length > 1 && (
                    <div
                      onClick={() => serialSplice(index)}
                      style={{ display: "flex" }}
                    >
                      <BsX style={{ margin: "auto" }} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <GreenButton
              onClick={setShowProducts(true)}
              classN="greenButton form"
              name="Add"
            />
          </form>
        ) : (
          <div className="content">
            <MiniAddProducts setFocusedItem={setFocusedItem} />
          </div>
        )}
      </div>
    </div>
  );
}

export default AddPopUp;
