// import React from 'react';

import NumberFormat from "react-number-format";
import { truncate } from "./truncate";

function ProductList({
  readOnly,
  setShowAddPopUp = () => {},
  lable = "Products/Services",
  setEditIndex = () => {},
  selectedProducts = [],
  showSerialNo = false,
  prefix,
  thousandsGroupStyle,
}) {
  return (
    <div className="appForm" style={{ overflow: "auto" }}>
      <label>{lable}</label>
      <table className="productTable">
        <thead>
          <tr style={{ borderRadius: 20 }}>
            <th>No.</th>
            <th style={{ width: showSerialNo ? "30%" : "60%" }}>Name</th>
            {showSerialNo && <th style={{ width: "40%" }}>Serial No.</th>}
            <th>Qty.</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
            <td />
            {showSerialNo && <td />}
            <td />
            <td />
          </tr>
          {selectedProducts.map((product, index) => (
            <tr key={index} onClick={() => setEditIndex(index + 1)}>
              <td>{index + 1}</td>
              <td>{truncate(product.name || "")}</td>
              {showSerialNo && (
                <td>{truncate(product?.serialNo?.join(", ") || "")}</td>
              )}
              <td>{product.billQty || ""}</td>
              <td>
                <NumberFormat
                  displayType="text"
                  thousandSeparator={true}
                  thousandsGroupStyle={thousandsGroupStyle}
                  prefix={prefix}
                  value={product.price || ""}
                />
              </td>
            </tr>
          ))}
          {/* <tr>
                <td>1</td>
                <td>Product Name</td>
                <td>12....ss563</td>
                <td>2</td>
                <td>$399</td>
                </tr>
                <tr>
                <td>2</td>
                <td>Product Name</td>
                <td>sd....u453</td>
                <td>1</td>
                <td>$99</td>
                </tr> */}
          {/* <tr>
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td>+</td>
          </tr> */}
        </tbody>
      </table>
      {!readOnly && (
        <div onClick={() => setShowAddPopUp(true)} className="addButton">
          Add Products
        </div>
      )}
    </div>
  );
}

export default ProductList;
