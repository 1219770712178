import React, { useEffect } from 'react';
import * as Realm from "realm-web";

// TODO Component for <app url>/redirect
function GoogleSignedIn() {

    useEffect(() => {
        Realm.handleAuthRedirect();
    }, []);
  
    return <h1 className="redirection">Signing in... Please Wait</h1>;
}

export default GoogleSignedIn;