import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import GreenButton from "../../components/GreenButton";
import HomeMenu from "../../components/HomeMenu";
import NavBar from "../../components/NavBar";
import ScreenNav from "../../components/ScreenNav";
import { useBusiness } from "../../providers/businessProvider";
import { useIsPc } from "../../providers/IsPcProvider";
import { useUser } from "../../providers/userProvider";

function ProductView() {
  const history = useHistory();
  const { userData } = useUser();
  const { isPc } = useIsPc();
  const { state: productId } = useLocation();
  const { getProduct } = useBusiness();
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    if (!productId) {
      console.log("redirect to productlist");
      history.replace("/productList");
    }

    getProduct(productId).then((data) => setProductData(data));

    //TODO go to products page if (location.state: products) in null

    return () => {
      setProductData(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="appBody">
      {isPc ? (
        <NavBar name={userData.name} />
      ) : (
        <ScreenNav screenName={productData?.name || "Product"} />
      )}
      <div className="child">
        {isPc ? <HomeMenu /> : null}
        <div className="contentBody">
          {!productData ? (
            "loading"
          ) : (
            <div className="content view">
              <div
                style={{
                  background: "lightgrey",
                  height: 300,
                  width: "auto",
                }}
              >
                {productData?.image && productData?.image[0] ? (
                  <img
                    style={{
                      objectFit: "contain",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                    alt="product"
                    src={productData.image[0]}
                  />
                ) : (
                  "No Image"
                )}
              </div>
              <div>
                <span
                  style={{
                    margin: "1rem",
                    border: "1px solid #dddddd",
                    width: "95%",
                    textAlign: "center",
                  }}
                >
                  Extra_INFO
                </span>
              </div>
              <div
                style={{
                  display: "block",
                  margin: "0 2rem",
                }}
              >
                <Properties obj={productData} />
                <GreenButton
                  style={{ margin: "50px 0" }}
                  btnStyle={{
                    width: "100%",
                  }}
                  name="Delete"
                />
                <GreenButton
                  style={{ margin: "50px 0" }}
                  btnStyle={{
                    width: "100%",
                  }}
                  name="Edit"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function Properties({ obj }) {
  return Object.entries(obj)
    .filter(
      ([key]) => !["image", "serialNos", "_id", "businessId"].includes(key)
    )
    .map(([key, value], index) => (
      <h2 key={index}>
        <span style={{ all: "initial" }}>{key}</span>
        {"  :  "}
        {value}
      </h2>
    ));
}

export default ProductView;
