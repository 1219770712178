import { useEffect, useState } from "react";
import { useIsPc } from "../../providers/IsPcProvider";
import { useUser } from "../../providers/userProvider";
import AppForm from "../../components/AppForm";
import HomeMenu from "../../components/HomeMenu";
import NavBar from "../../components/NavBar";
import ScreenNav from "../../components/ScreenNav";
import GreenButton from "../../components/GreenButton";
import ProductList from "../../components/ProductList";
import TwoIn1 from "../../components/TwoIn1";
import Address from "../../components/Address";
import AddPopUp from "../../components/AddPopUp";
import EditPopUp from "../../components/EditPopUp";
import { useBusiness } from "../../providers/businessProvider";
// import PrintPopUp from "../../components/PrintPopUp";
import { BsChevronDown } from "react-icons/bs";
import Collapsible from "react-collapsible";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import { BsX } from "react-icons/bs";

function CreateBill() {
  const { userData } = useUser();
  const { createBill, businessData } = useBusiness();
  const history = useHistory();
  const { isPc } = useIsPc();

  const [showAddPopUp, setShowAddPopUp] = useState(false);
  const [editNo, setEditNo] = useState(null);
  const [showSerialNo, setShowSerialNo] = useState(false);
  const [loading, setLoading] = useState(false);

  const [customerName, setCustomerName] = useState("");
  const [mobileNumbers, setMobileNumbers] = useState([""]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [address, setAddress] = useState({});
  const [discount, setDiscount] = useState("");
  const [total, setTotal] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [info, setInfo] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [dueAmount, setDueAmount] = useState("");
  const [refresh, setRefresh] = useState(true);

  const resetState = () => {
    setRefresh(!refresh);
    setCustomerName("");
    setMobileNumbers([""]);
    setSelectedProducts([]);
    setAddress({});
    setDiscount("");
    setTotal("");
    setPaidAmount("");
    setInfo("");
  };

  const handleAddMoreNo = () => {
    let arr = mobileNumbers.slice();
    arr.push("");
    setMobileNumbers(arr);
  };

  const noSplice = (index) => {
    let arr = mobileNumbers.slice();
    arr.splice(index, 1);
    setMobileNumbers(arr);
  };

  useEffect(() => {
    setDueAmount(total - paidAmount);
    // return () => {
    //   cleanup
    // }
  }, [paidAmount, total]);

  useEffect(() => {
    // var sum
    let sum = selectedProducts.reduce((sum, product) => {
      setShowSerialNo(product.serialNo ? true : false);
      let itemTotal = product.price * product.billQty;
      return {
        price: (sum.price || 0) + itemTotal,
        tax: (sum.tax || 0) + ((product.taxPercent || 0) / 100) * itemTotal,
      };
    }, 0);
    // let totalTaxPercent = (sum.tax / sum.price) * 100;
    let totalPrice = sum.price - discount;
    let totalTax = sum.tax * (1 - discount / sum.price);
    setTotal(totalPrice || "");
    totalTax = Math.round(totalTax * 100) / 100;
    setTaxAmount(totalTax || "");
    setPaidAmount(totalPrice || "");

    return () => {};
  }, [discount, selectedProducts]);

  return (
    <div className="appBody">
      {isPc ? (
        <NavBar name={userData.name} />
      ) : (
        <ScreenNav screenName="Create Bill" />
      )}
      <div className="child">
        {isPc ? <HomeMenu /> : null}
        <div className="contentBody">
          {(showAddPopUp || editNo || loading) && (
            // popups------------------------------------------------
            <div>
              <div
                onClick={() => {
                  setShowAddPopUp(false);
                  setEditNo(null);
                }}
                className="backdrop"
              />
              {showAddPopUp && (
                <AddPopUp
                  selectedProducts={selectedProducts}
                  setSelectedProducts={setSelectedProducts}
                  setShowPopUp={setShowAddPopUp}
                />
              )}
              {editNo && (
                <EditPopUp
                  editNo={editNo}
                  setEditNo={setEditNo}
                  selectedProducts={selectedProducts}
                  setSelectedProducts={setSelectedProducts}
                />
              )}
              {/* {showPrint && (
                <PrintPopUp
                  setShowPrint={setShowPrint}
                  resetState={resetState}
                  customerName={customerName}
                  mobileNumber={mobileNumber}
                  address={address}
                  selectedProducts={selectedProducts}
                  discount={discount}
                  total={total}
                  amountPaid={amountPaid}
                  info={info}
                />
              )} */}
            </div>
            //popup ene----------------------------------------------------------------
          )}
          <form
            className="content form"
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              createBill({
                selectedProducts,
                discount,
                taxAmount,
                total,
                paidAmount,
                dueAmount,
                customerName,
                mobileNumbers: mobileNumbers.filter(
                  (el) => el !== "" && el != null
                ),
                address,
                info,
              })
                .then(
                  (bill) => {
                    bill
                      ? history.push({
                          pathname: "/print",
                          // search: "print",
                          state: { bill, businessData, showSerialNo },
                        })
                      : resetState();
                  },
                  (error) => {
                    console.log(error);
                  }
                )
                .finally(() => setLoading(false));
            }}
          >
            <AppForm
              name="Customer Name"
              placeholder="Name"
              required
              value={customerName}
              setValue={setCustomerName}
            />
            <ProductList
              setShowAddPopUp={setShowAddPopUp}
              setEditIndex={setEditNo}
              selectedProducts={selectedProducts}
              showSerialNo={showSerialNo}
              prefix="₹"
              thousandsGroupStyle="lakh"
            />
            {/* <div onClick={() => setShowAddPopUp(true)} className="addButton">
              Add Products
            </div> */}
            <TwoIn1
              placeholder1=" 0.00 "
              placeholder2="0.00"
              type1="number"
              type2="number"
              name1={"GST"}
              value1={taxAmount}
              name2={"Discount"}
              value2={discount}
              setValue2={setDiscount}
              readOnly1
              useNumberFormat1
              useNumberFormat2
              prefix1={"₹"}
              prefix2={"₹"}
              thousandsGroupStyle1="lakh"
              thousandSeparator1
              thousandsGroupStyle2="lakh"
              thousandSeparator2
            />
            <AppForm
              name="Total"
              value={total}
              setValue={setTotal}
              readOnly
              placeholder="0.00"
              useNumberFormat
              prefix={"₹"}
              thousandsGroupStyle="lakh"
              thousandSeparator
            />
            <AppForm
              name="Amount Paid"
              value={paidAmount}
              setValue={setPaidAmount}
              placeholder={"0.00"}
              useNumberFormat
              prefix={"₹"}
              thousandsGroupStyle="lakh"
              thousandSeparator
            />
            <Collapsible
              transitionTime={90}
              trigger={[
                "Customer details",
                <BsChevronDown key="customerDetails" />,
              ]}
            >
              <div
                className={
                  mobileNumbers.length > 1 ? "appForm numbers" : "appForm"
                }
              >
                <label>Mobile Number</label>
                {mobileNumbers.map((value, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <NumberFormat
                      style={{ flex: 1 }}
                      // prefix="+91 "
                      placeholder="123 456 7890"
                      format="+91 ### ### ####"
                      value={value}
                      onValueChange={({ value }) => {
                        let arr = mobileNumbers.slice();
                        arr[index] = value;
                        setMobileNumbers(arr);
                      }}
                    />
                    {mobileNumbers.length > 1 && (
                      <div
                        onClick={() => noSplice(index)}
                        style={{ display: "flex" }}
                      >
                        <BsX style={{ margin: "auto" }} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div
                onClick={handleAddMoreNo}
                className="addButton"
                // style={{ margin: "10px 0px", borderRadius: 20 }}
              >
                Add more
              </div>
              {/* <AppForm
                name="Mobile No."
                placeholder="123 456 7890"
                format="### ### ####"
                value={mobileNumbers}
                setValue={setMobileNumbers}
                useNumberFormat
              /> */}
              <Address key={String(refresh)} setAddress={setAddress} />
            </Collapsible>
            <AppForm
              placeholder="Have a nice day!"
              name="Extra Info"
              value={info}
              setValue={setInfo}
            />
            <GreenButton
              classN="greenButton form"
              name="Create"
              // style={{ margin: "5rem auto" }}
            />
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateBill;
