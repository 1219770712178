import AppForm from "./AppForm";

function TwoIn1({
  placeholder1,
  placeholder2,
  name1,
  name2,
  value1,
  value2,
  type1,
  type2,
  min1,
  min2,
  prefix1,
  prefix2,
  isPercent1,
  isPercent2,
  readOnly1,
  readOnly2,
  useNumberFormat1,
  useNumberFormat2,
  thousandsGroupStyle1,
  thousandsGroupStyle2,
  thousandSeparator1,
  thousandSeparator2,
  setValue1 = () => {},
  setValue2 = () => {},
}) {
  return (
    <div className="half">
      <AppForm
        readOnly={readOnly1}
        style={{ width: "45%" }}
        placeholder={placeholder1}
        name={name1}
        type={type1}
        min={min1}
        value={value1}
        isPercent={isPercent1}
        setValue={setValue1}
        useNumberFormat={useNumberFormat1}
        prefix={prefix1}
        thousandsGroupStyle={thousandsGroupStyle1}
        thousandSeparator={thousandSeparator1}
      />
      <AppForm
        readOnly={readOnly2}
        style={{ width: "45%" }}
        placeholder={placeholder2}
        name={name2}
        type={type2}
        min={min2}
        value={value2}
        isPercent={isPercent2}
        setValue={setValue2}
        useNumberFormat={useNumberFormat2}
        prefix={prefix2}
        thousandsGroupStyle={thousandsGroupStyle2}
        thousandSeparator={thousandSeparator2}
      />
      {/* <div className="appForm twoIn1">
        <label>{name1}</label>
        <input
          placeholder={placeholder1}
          type={type1}
          autoComplete="off"
          value={value1}
          onChange={(e) => setValue1(e.target.value)}
        />
      </div>
      <div className="appForm twoIn1">
        <label>{name2}</label>
        <input
          placeholder={placeholder2}
          type={type2}
          autoComplete="off"
          value={value2}
          onChange={(e) => setValue2(e.target.value)}
        />
      </div> */}
    </div>
  );
}

export default TwoIn1;
