import { useEffect, useState } from "react";
import { useIsPc } from "../../providers/IsPcProvider";
import { useUser } from "../../providers/userProvider";
import AppForm from "../../components/AppForm";
import HomeMenu from "../../components/HomeMenu";
import NavBar from "../../components/NavBar";
import ScreenNav from "../../components/ScreenNav";
import GreenButton from "../../components/GreenButton";
import ProductList from "../../components/ProductList";
import TwoIn1 from "../../components/TwoIn1";
import Address from "../../components/Address";
import EditPopUp from "../../components/EditPopUp";
import { useBusiness } from "../../providers/businessProvider";
import { useHistory, useLocation } from "react-router-dom";
import NumberFormat from "react-number-format";

function BillView() {
  const { state } = useLocation();
  const { _id } = state;
  const [billData, setBillData] = useState(null);

  const { userData } = useUser();
  const { businessData, getBill } = useBusiness();

  const history = useHistory();
  const { isPc } = useIsPc();

  const [showAddPopUp, setShowAddPopUp] = useState(false);
  const [editNo, setEditNo] = useState(null);
  const [showSerialNo, setShowSerialNo] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!_id) {
      console.log("redirect to Bill Llist");
      history.replace("/billList");
    }
    setLoading(true);
    getBill(_id)
      .then(
        (data) => {
          // console.log(data);
          setShowSerialNo(
            data.products.some((product) => product?.serialNo?.length > 0)
          );
          setBillData(data);
        },
        (e) => alert(e.message)
      )
      .finally(() => setLoading(false));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  return (
    <div className="appBody">
      {isPc ? (
        <NavBar name={userData.name} />
      ) : (
        <ScreenNav screenName="Bill View" />
      )}
      <div className="child">
        {isPc ? <HomeMenu /> : null}
        <div className="contentBody">
          {(showAddPopUp || editNo || loading) && (
            // popups------------------------------------------------
            <div>
              <div
                onClick={() => {
                  setShowAddPopUp(false);
                  setEditNo(null);
                }}
                className="backdrop"
              />
              {editNo && (
                <EditPopUp
                  readOnly
                  editNo={editNo}
                  setEditNo={setEditNo}
                  selectedProducts={billData?.products || []}
                  //   setSelectedProducts={setSelectedProducts}
                />
              )}
            </div>
            //popup ene----------------------------------------------------------------
          )}
          <div
            //   form
            className="content form"
            // onSubmit={(e) => {
            //   e.preventDefault();
            // }}
          >
            <AppForm
              name="Customer Name"
              placeholder="Name"
              readOnly
              value={billData?.customer?.name || billData?.customerName || "--"}
            />
            <ProductList
              readOnly
              //   setShowAddPopUp={setShowAddPopUp}
              setEditIndex={setEditNo}
              selectedProducts={billData?.products || []}
              showSerialNo={showSerialNo}
              prefix="₹"
              thousandsGroupStyle="lakh"
            />
            <TwoIn1
              placeholder1=" 0.00 "
              placeholder2="0.00"
              type1="number"
              type2="number"
              name1={"GST"}
              value1={Math.round(billData?.taxAmount * 100) / 100 || ""}
              name2={"Discount"}
              value2={billData?.discount || ""}
              //   setValue2={setDiscount}
              readOnly1
              readOnly2
              useNumberFormat1
              useNumberFormat2
              prefix1={"₹"}
              prefix2={"₹"}
              thousandsGroupStyle1="lakh"
              thousandSeparator1
              thousandsGroupStyle2="lakh"
              thousandSeparator2
            />
            <AppForm
              name="Total"
              value={billData?.totalAmount || ""}
              //   setValue={setTotal}
              readOnly
              placeholder="0.00"
              useNumberFormat
              prefix={"₹"}
              thousandsGroupStyle="lakh"
              thousandSeparator
            />
            <AppForm
              name="Amount Paid"
              value={billData?.amountPaid || ""}
              //   setValue={setPaidAmount}
              readOnly
              placeholder={"0.00"}
              useNumberFormat
              prefix={"₹"}
              thousandsGroupStyle="lakh"
              thousandSeparator
            />
            {!!billData?.dueAmount && (
              <AppForm
                name="Amount Due"
                value={billData?.dueAmount || ""}
                //   setValue={setPaidAmount}
                readonly
                placeholder={"0.00"}
                useNumberFormat
                prefix={"₹"}
                thousandsGroupStyle="lakh"
                thousandSeparator
              />
            )}
            {/* <Collapsible
              transitionTime={90}
              trigger={[
                "Customer details",
                <BsChevronDown key="customerDetails" />,
              ]}
            > */}
            {billData?.customer?.mobileNumbers?.length > 0 && (
              <div
                className={
                  billData?.customer?.mobileNumbers.length > 1
                    ? "appForm numbers readOnly"
                    : "appForm"
                }
              >
                <label>Mobile Number</label>
                {billData?.customer?.mobileNumbers.map((value, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <NumberFormat
                      style={{ flex: 1 }}
                      // prefix="+91 "
                      readOnly
                      placeholder="123 456 7890"
                      format="+91 ### ### ####"
                      value={value}
                    />
                  </div>
                ))}
              </div>
            )}
            {Object.values(billData?.customer?.address || {}).filter(
              (el) => el !== "" && el != null
            ).length > 0 && (
              <Address
                // key={String(refresh)}
                address={billData?.customer?.address || undefined}
                // setAddress={setAddress}
              />
            )}
            {/* </Collapsible> */}
            {billData?.info && (
              <AppForm
                readOnly
                placeholder="Have a nice day!"
                name="Extra Info"
                value={billData?.info}
                //   setValue={setInfo}
              />
            )}
            <GreenButton
              onClick={() => {
                history.push({
                  pathname: "/print",
                  state: { bill: billData, businessData, showSerialNo },
                });
              }}
              classN="greenButton form"
              name="Print"
              // style={{ margin: "5rem auto" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillView;
