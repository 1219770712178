import React, { useState, useRef } from "react";
import GreenButton from "../../components/GreenButton";
import AppForm from "../../components/AppForm";
import TwoIn1 from "../../components/TwoIn1";
import { useBusiness } from "../../providers/businessProvider";
import Collapsible from "react-collapsible";
import { BsChevronDown } from "react-icons/bs";
import { BsX } from "react-icons/bs";
import { ReactComponent as ImgPlaceholder } from "../../assets/imgPlaceholder.svg";

function MiniAddProducts({ setFocusedItem }) {
  const { addProduct } = useBusiness();
  const [image, setImage] = useState(null);
  const [productName, SetProductName] = useState("");
  const [mrp, setMrp] = useState("");
  const [tax, setTax] = useState("");
  const [cost, setCost] = useState("");
  const [serialNoList, setSerialNoList] = useState([""]);
  const [discription, setDiscription] = useState("");
  const [qty, setQty] = useState("");
  const imageInputRef = useRef();
  const [loading, setLoading] = useState(false);

  function initstates() {
    SetProductName("");
    setMrp("");
    setCost("");
    setQty("");
    setSerialNoList([""]);
    setDiscription("");
  }

  const removeImg = () => {
    imageInputRef.current.value = "";
    setImage(null);
  };

  const onImageChange = (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.onload = function (event) {
      setImage(event.target.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  function serialPush() {
    if (serialNoList.length < qty) {
      let arr = serialNoList.slice();
      arr.push("");
      setSerialNoList(arr);
    } else {
      alert(`Cannot add Serial No more then Quantity, that is ${qty || 0}.`);
    }
  }

  function serialSplice(index) {
    let arr = serialNoList.slice();
    arr.splice(index, 1);
    setSerialNoList(arr);
  }

  return (
    <>
      {loading && (
        // popups------------------------------------------------
        <div>
          <div className="backdrop" />
        </div>
        //popup ene----------------------------------------------------------------
      )}
      <form
        className="content form"
        onSubmit={(e) => {
          e.preventDefault();
          addProduct({
            productName,
            serialNoList,
            mrp,
            purchasePrice: cost,
            qty: Number(qty || 1),
            tax,
            info: discription,
            image,
          })
            .then(
              (re) => {
                console.log(`Insert id = ${re._id}`);
                initstates();
                setFocusedItem(re);
              },
              (err) => {
                console.log(err.message);
              }
            )
            .finally(() => setLoading(false));
          setLoading(true);
        }}
      >
        <AppForm
          name="Product Name"
          required
          placeholder="Name"
          value={productName}
          setValue={SetProductName}
        />
        <TwoIn1
          placeholder1="0.00"
          placeholder2="1"
          type1="number"
          type2="number"
          min1={0}
          min2={0}
          useNumberFormat1
          // useNumberFormat2
          prefix1={"₹"}
          // prefix2={"₹"}
          thousandsGroupStyle1="lakh"
          thousandSeparator1
          // thousandsGroupStyle2="lakh"
          // thousandSeparator2
          name1={"Selling price"}
          value1={mrp}
          name2={"Quantity"}
          value2={qty}
          setValue1={setMrp}
          setValue2={setQty}
        />
        {/* <AppForm placeholder="0" name="Quantity" value={qty} setValue={setQty} /> */}
        <AppForm
          useNumberFormat
          isPercent
          placeholder="0%"
          name="GST"
          value={tax}
          setValue={setTax}
        />
        <AppForm
          placeholder="About"
          name="Discription"
          value={discription}
          setValue={setDiscription}
        />
        {
          //Add image
        }
        <Collapsible
          transitionTime={90}
          trigger={["Add Image", <BsChevronDown key="1" />]}
        >
          <div className="appForm">
            {/* <label>Image</label> */}
            {image ? (
              <img className="image" alt="Product" src={image} />
            ) : (
              <ImgPlaceholder className="image" style={{ width: "100px" }} />
            )}
            {image && (
              <label onClick={removeImg} className="greenButton fileUpload">
                Remove
              </label>
            )}
            <label className="greenButton fileUpload">
              {image ? "Change" : "Select"}
              <input
                ref={imageInputRef}
                style={{ display: "none" }}
                type="file"
                accept=".jpeg, .png, .jpg"
                name={productName}
                onChange={onImageChange}
              />
            </label>
          </div>
        </Collapsible>
        <Collapsible
          transitionTime={90}
          trigger={["Serial No.", <BsChevronDown key="2" />]}
        >
          <div
            className={serialNoList.length > 1 ? "appForm serial" : "appForm"}
          >
            {/* <label>Serial No.</label> */}
            {serialNoList.map((value, index) => (
              <div
                key={index}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <input
                  style={{ flex: 1 }}
                  placeholder={`${index + 1}. Serial No.`}
                  value={value}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      serialPush();
                    } else if (e.key === "Delete") {
                      // console.log("delete");
                      serialSplice(index);
                    }
                  }}
                  onChange={(e) => {
                    let arr = serialNoList.slice();
                    arr[index] = e.target.value;
                    setSerialNoList(arr);
                  }}
                />
                {serialNoList.length > 1 && (
                  <div
                    onClick={() => serialSplice(index)}
                    style={{ display: "flex" }}
                  >
                    <BsX style={{ margin: "auto" }} />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div onClick={() => serialPush()} className="addButton">
            Add more
          </div>
        </Collapsible>
        {/* TODO stored at */}
        <GreenButton name="Add" classN="greenButton form" />
      </form>
    </>
  );
}

export default MiniAddProducts;
