import { createContext, useContext, useEffect, useState } from "react";
import * as Realm from "realm-web";
import firebase from "firebase/app";
import "firebase/auth";
// import googleOneTap from 'google-one-tap';

const firebaseConfig = {
  apiKey: "AIzaSyDxzktdpFPW7TJ0bGcU51CG76wMpZ-hZuM",
  // authDomain: "business-busivisy.firebaseapp.com",
  authDomain: "business.busivisy.com",
  projectId: "business-busivisy",
  storageBucket: "business-busivisy.appspot.com",
  messagingSenderId: "467561829363",
  appId: "1:467561829363:web:fd059f48acde47355cb886",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const AuthContext = createContext();
const APP_ID = "business-gdweu";

export const AuthProvider = ({ children }) => {
  const [app, setApp] = useState(new Realm.App(APP_ID));
  const [currentUser, setCurrentUser] = useState(app.currentUser);

  useEffect(() => {
    setApp(new Realm.App(APP_ID));
  }, []);

  async function loginCustomJwt(jwt) {
    // Create a Custom JWT credential
    const credentials = Realm.Credentials.jwt(jwt);
    try {
      // Authenticate the user
      const user = await app.logIn(credentials);
      // `App.currentUser` updates to match the logged in user
      if (!user.id === app.currentUser.id)
        console.error("user.id!=current user");
      return user;
    } catch (err) {
      console.error("Failed to log in", err);
    }
  }

  //sign in with email and password
  async function signinEmailPassword(email, password) {
    try {
      const userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      const user = await loginCustomJwt(
        await userCredential.user?.getIdToken()
      );
      setCurrentUser(user);
    } catch (err) {
      alert(`Failed Sign In! ${err.message}.`);
      console.log("Failed Sign In!", err.code);
    }

    // const credentials = Realm.Credentials.emailPassword(email, password);
    // try {
    //   // Authenticate the user
    //   const user = await app.logIn(credentials);
    //   // `App.currentUser` updates to match the logged in user
    //   if (!user.id === app.currentUser.id)
    //     throw console.error("!user.id === app.currentUser.id");
    //   setCurrentUser(user);
    // } catch (err) {
    //   alert(`Failed to log in! ${err.error}.`);
    //   console.error("Failed to log in", err.message);
    // }
  }

  //sign Up with email and password
  async function signUpEmailPassword(email, password, name) {
    try {
      const userCredential = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      const user = await loginCustomJwt(
        await userCredential.user?.getIdToken()
      );
      const usersCollection = user
        .mongoClient("mongodb-atlas")
        .db("busivisy")
        .collection("users");
      await usersCollection.updateOne(
        { _id: user.id },
        { $set: { name: name } },
        { upsert: true }
      );
      setCurrentUser(user);
    } catch (err) {
      alert(`Failed Sign Up! ${err.message}.`);
      console.log("Failed Sign Up!", err.code);
    }

    // const credentials = Realm.Credentials.emailPassword(email, password);
    // try {
    //   await app.emailPasswordAuth.registerUser(email, password);
    //   const user = await app.logIn(credentials);
    //   const usersCollection = user
    //     .mongoClient("mongodb-atlas")
    //     .db("busivisy")
    //     .collection("users");
    //   await usersCollection.updateOne(
    //     { _id: user.id },
    //     { $set: { name: name } },
    //     { upsert: true }
    //   );
    //   setCurrentUser(user);
    // } catch (err) {
    //   alert(`Failed Sign Up! ${err.error}.`);
    //   console.log("Failed Sign Up!", err.message);
    // }
    // console.log("signed up");
  }

  //Sign in with google
  async function googleSignin() {
    // // const redirectUri = "http://localhost:5000/redirect"; // serve -s build
    // const redirectUri = "http://localhost:3000/redirect"; //npm start
    // // const redirectUri = "https://business.app.busivisy.com/redirect"; // Production
    // const credentials = Realm.Credentials.google(redirectUri);

    var provider = new firebase.auth.GoogleAuthProvider();
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      const user = await loginCustomJwt(await result.user.getIdToken());
      setCurrentUser(user);
      // alert(JSON.stringify(result.user.toJSON()));
    } catch (error) {
      alert(error.message);
    }
    // Calling logIn() opens a Google authentication screen in a new window.
    // app.logIn(credentials).then((user) => {
    //   setCurrentUser(user);
    //   console.log(`Logged in with id: ${user.id}`);
    // });

    // IMPORTENT call this when redirected ==>> Realm.handleAuthRedirect();
    // Realm.handleAuthRedirect();
  }

  //Log out
  async function logOut() {
    // Log out the currently active user
    await app.currentUser?.logOut();
    await firebase.auth().signOut();
    // If another user was logged in too, they're now the current user.
    // Otherwise, app.currentUser is null.
    setCurrentUser(app.currentUser);
  }

  const wrapped = {
    ...app,
    currentUser,
    logOut,
    googleSignin,
    signinEmailPassword,
    signUpEmailPassword,
  };

  return (
    <AuthContext.Provider value={wrapped}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const authProvider = useContext(AuthContext);
  if (!authProvider) {
    throw new Error(`You must call useAuth() inside of a <AuthProvider />`);
  }
  return authProvider;
};
