import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { IsPcProvider } from "../../providers/IsPcProvider";
import AddCustomer from "./AddCustomer";
import AddProduct from "./AddProduct";
import AddReciept from "./AddReceipt";
import CreateBill from "./CreateBill";
import BillList from "./BillList";
import Home from "./Home";
import Print from "./Print";
import ProductList from "./ProductList";
import ProductView from "./ProductView";
import BillView from "./BillView";

function Main() {
  return (
    <IsPcProvider>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/createBill">
            <CreateBill />
          </Route>
          <Route exact path="/addProduct">
            <AddProduct />
          </Route>
          <Route exact path="/addReceipt">
            <AddReciept />
          </Route>
          <Route exact path="/addCustomer">
            <AddCustomer />
          </Route>
          <Route exact path="/billList">
            <BillList />
          </Route>
          <Route exact path="/billView">
            <BillView />
          </Route>
          <Route exact path="/productList">
            <ProductList />
          </Route>
          <Route exact path="/productView">
            <ProductView />
          </Route>
          <Route exact path="/print">
            <Print />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Router>
    </IsPcProvider>
  );
}

export default Main;
