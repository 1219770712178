import React from "react";
import { useAuth } from "../providers/authProvider";
import { ReactComponent as GoogleLogo } from "../assets/GoogleLogo.svg";

function LogInWith(props) {
  const { googleSignin } = useAuth();
  return (
    <div className="signInWith" onClick={googleSignin}>
      <GoogleLogo className="icon" />
      <span>Sign In with Google</span>
    </div>
  );
}

export default LogInWith;
