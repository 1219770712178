import React, { createContext, useContext, useEffect, useState } from "react";
const isPcContext = createContext();

export const IsPcProvider = ({ children }) => {
  const [isPc, setIspc] = useState(false);

  const showPc = () => {
    if (window.innerWidth <= 960) {
      // console.log('Mobile============');
      setIspc(false);
    } else {
      // console.log('Pc============');
      setIspc(true);
    }
  };

  useEffect(() => {
    showPc();
    window.addEventListener("resize", showPc);
    return () => {
      // console.log('Cleanup===============');
      window.removeEventListener("resize", showPc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <isPcContext.Provider value={{ isPc }}>{children}</isPcContext.Provider>
  );
};

export const useIsPc = () => {
  const isPc = useContext(isPcContext);
  if (!isPc) {
    throw new Error(`You must call useIsPc() inside of a <AuthProvider />`);
  }
  return isPc;
};
