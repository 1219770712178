import { Link } from "react-router-dom";

function ItemUi({ list }) {
  return (
    <ul className="listStyle">
      {list.map((item) => (
        <Link
          key={item._id}
          style={{ all: "unset" }}
          className="link"
          to={{
            pathname: "/productView",
            search: item.name,
            state: item._id.toString(),
          }}
        >
          <li>{item.name}</li>
        </Link>
      ))}
    </ul>
  );
}

export default ItemUi;
