import React from "react";
import { Link } from "react-router-dom";

function BottomCard(props) {
  const { name, number, to } = props;
  return (
    <Link style={{ all: "unset" }} to={to}>
      <div className="bottomCard">
        <p>{name}</p>
        <p>{number}</p>
      </div>
    </Link>
  );
}

export default BottomCard;
