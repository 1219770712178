import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ReactComponent as BLogo } from "../assets/logo.svg";
import { BsChevronRight } from "react-icons/bs";
import { useAuth } from "../providers/authProvider";
import { capitalizeFirstLetter } from "../assets/capatalizeFirst";
import { getFirstName } from "../assets/getFirstNamr";

function NavBar(props) {
  const [showSidebar, SetShowSidebar] = useState(false);
  const { name } = props;
  const { logOut } = useAuth();
  return (
    <>
      <nav className="navBar">
        <Link className="iconLink" to="/">
          <BLogo className="logo" />
        </Link>
        <h2>
          Hi, {name ? capitalizeFirstLetter(getFirstName(name)) : "Stranger"}
        </h2>
        <div className="iconLink" onClick={() => SetShowSidebar(!showSidebar)}>
          <FaBars className="icon" />
        </div>
      </nav>
      {showSidebar && (
        <nav
          onClick={() => SetShowSidebar(!showSidebar)}
          className="backdrop"
        />
      )}
      <nav className={showSidebar ? "sidebar active" : "sidebar"}>
        <div className="navBar">
          <div
            onClick={() => SetShowSidebar(!showSidebar)}
            className="iconLink"
          >
            <BsChevronRight className="icon" />
          </div>
          <h2>Options</h2>
          <div className="icon" />
        </div>
        <button onClick={logOut}>Log Out</button>
      </nav>
      {/* </> */}
    </>
  );
}

export default NavBar;
