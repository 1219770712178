import React from "react";

function GreenButton({
  onClick,
  style,
  btnStyle,
  name,
  classN = "greenButton",
}) {
  return (
    <div style={{ textAlign: "center", ...style }}>
      <button onClick={onClick} style={btnStyle} className={classN}>
        {name}
      </button>
    </div>
  );
}

export default GreenButton;
