import "../App/styles/css/App.css";
import Auth from "./views/auth/Auth";
import Main from "./views/main/Main";
import { AuthProvider, useAuth } from "./providers/authProvider";
import { BusinessProvider } from "./providers/businessProvider";
import { UserProvider, useUser } from "./providers/userProvider";
import CreateBusiness from "./views/auth/CreateBusiness";
// import { useEffect, useState } from 'react';

// let deferredPrompt;

function App() {
  // const [installable, setInstallable] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("beforeinstallprompt", (e) => {
  //     // Prevent the mini-infobar from appearing on mobile
  //     e.preventDefault();
  //     // Stash the event so it can be triggered later.
  //     deferredPrompt = e;
  //     // Update UI notify the user they can install the PWA
  //     setInstallable(true);
  //   });

  //   window.addEventListener('appinstalled', () => {
  //     // Log install to analytics
  //     console.log('INSTALL: Success');
  //   });
  // }, []);

  // const handleInstallClick = () => {
  //     // Hide the app provided install promotion
  //     setInstallable(false);
  //     // Show the install prompt
  //     deferredPrompt.prompt();
  //     // Wait for the user to respond to the prompt
  //     deferredPrompt.userChoice.then((choiceResult) => {
  //       if (choiceResult.outcome === 'accepted') {
  //         console.log('User accepted the install prompt');
  //       } else {
  //         console.log('User dismissed the install prompt');
  //       }
  //     });
  // };

  return (
    <AuthProvider>
      <RequireLoggedInUser>
        <UserProvider>
          <RequireBusiness>
            <BusinessProvider>
              <Main />
            </BusinessProvider>
          </RequireBusiness>
        </UserProvider>
      </RequireLoggedInUser>
      {
        // installable &&
        // <button className="install-button" onClick={handleInstallClick}>
        //   INSTALL ME
        // </button>
      }
    </AuthProvider>
  );
}

const RequireLoggedInUser = ({ children }) => {
  const authProvider = useAuth();
  return authProvider.currentUser ? children : <Auth />;
};

const RequireBusiness = ({ children }) => {
  const { userData, userLoading } = useUser();

  if (userLoading) {
    return <h1>Loading</h1>;
  } else if (!userData) {
    return <h1>failed to load</h1>;
  } else if (userData.businesses) {
    if (userData.businesses.length > 0) {
      return children;
    }
    // else {
    //   return <CreateBusiness/>;
    // }
  }
  // else {
  return <CreateBusiness />;
  // }
};

export default App;
