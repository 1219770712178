import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

function NavigationButton(props) {
  const { name, to } = props;
  return (
    <Link style={{ all: "unset" }} to={to}>
      <div className="navButton">
        <h3>{name}</h3>
        <BsArrowRight className="icon" />
      </div>
    </Link>
  );
}

export default NavigationButton;
