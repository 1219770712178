import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { billNoFormat } from "../../assets/billNoFormat";
import AppForm from "../../components/AppForm";
import HomeMenu from "../../components/HomeMenu";
import NavBar from "../../components/NavBar";
import ScreenNav from "../../components/ScreenNav";
import { useBusiness } from "../../providers/businessProvider";
import { useIsPc } from "../../providers/IsPcProvider";
import { useUser } from "../../providers/userProvider";

function BillList() {
  const history = useHistory();
  const { userData } = useUser();
  const { billSearch } = useBusiness();
  const { isPc } = useIsPc();
  const [search, setSearch] = useState("");
  const [listItems, setListItems] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleSellect = (_id) => {
    history.push({
      pathname: "/billView",
      state: { _id },
    });
  };

  function ItemUi({ list }) {
    return (
      <ul className="listStyle">
        {list.map((item) => (
          <li onClick={() => handleSellect(item._id.toString())} key={item._id}>
            <span>{item?.customer?.name || item?.customerName || "--"}</span>
            <span>#{billNoFormat(item.billNo) || "--"}</span>
          </li>
        ))}
      </ul>
    );
  }

  useEffect(() => {
    setLoading(true);
    billSearch(search)
      .then(
        (value) => {
          setListItems(value);
          setError(null);
        },
        (err) => {
          console.log(err.message);
          setError(err);
        }
      )
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className="appBody">
      {isPc ? (
        <NavBar name={userData.name} />
      ) : (
        <ScreenNav screenName="Bills" />
      )}
      <div className="child">
        {isPc ? <HomeMenu /> : null}
        <div className="contentBody" style={{ overflow: "unset" }}>
          <div className="content form" style={{ flex: "unset" }}>
            <AppForm value={search} placeholder="Search" setValue={setSearch} />
          </div>
          <div style={{ overflow: "auto" }}>
            {loading ? (
              "loading"
            ) : error ? (
              error.message
            ) : (
              <ItemUi list={listItems} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillList;
