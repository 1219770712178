import { useState } from "react";
import AppForm from "../../components/AppForm";
import GreenButton from "../../components/GreenButton";
import HomeMenu from "../../components/HomeMenu";
import NavBar from "../../components/NavBar";
import ProductList from "../../components/ProductList";
import ScreenNav from "../../components/ScreenNav";
import { useIsPc } from "../../providers/IsPcProvider";
import { useUser } from "../../providers/userProvider";

function AddReciept() {
  const { userData } = useUser();
  const { isPc } = useIsPc();
  const [cName, SetCName] = useState("");

  return (
    <div className="appBody">
      {isPc ? (
        <NavBar name={userData.name} />
      ) : (
        <ScreenNav screenName="Add Reciepts" />
      )}
      <div className="child">
        {isPc ? <HomeMenu /> : null}
        <div className="contentBody">
          <form className="content form" style={{ flex: "unset" }}>
            <AppForm name="Vendor Name" value={cName} setValue={SetCName} />
            <ProductList />
          </form>
          <div className="fotter">
            <ul className="listStyle">
              <li>Total</li>
            </ul>
            <GreenButton name="Add" classN="greenButton form" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddReciept;
