import React, { useEffect, useState } from "react";
import { BsX } from "react-icons/bs";
import { useBusiness } from "../providers/businessProvider";
import AppDisplay from "./AppDisplay";
import AppForm from "./AppForm";
import GreenButton from "./GreenButton";

function EditPopUp({
  readOnly,
  editNo,
  setEditNo,
  selectedProducts,
  setSelectedProducts = () => {},
}) {
  const editIndex = editNo - 1;
  const { findOneProduct } = useBusiness(); //todo replace with getProduct
  const [productData, setProductData] = useState(null);
  const [serialNo, setSerialNo] = useState(
    selectedProducts[editIndex]?.serialNo || [""]
  );

  useEffect(() => {
    findOneProduct({ _id: selectedProducts[editIndex].productId }).then(
      (result) => setProductData(result),
      (err) => alert(err.error)
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editIndex, selectedProducts]);

  const remove = () => {
    let arr = selectedProducts.slice();
    arr.splice(editIndex, 1);
    setSelectedProducts(arr);
    setEditNo(null);
  };

  const serialSplice = (index) => {
    let arr = serialNo.slice();
    arr.splice(index, 1);
    setSerialNo(arr);
  };

  const handleAddMoreSerial = () => {
    if (serialNo.length < selectedProducts[editIndex].billQty) {
      let arr = serialNo.slice();
      arr.push("");
      setSerialNo(arr);
    } else {
      alert(
        `Cannot add Serial No more then Quantity, that is ${
          selectedProducts[editIndex].billQty || 0
        }.`
      );
    }
  };

  return (
    <div className="popUp">
      <nav className="navBar" style={{ height: "10%" }}>
        <div onClick={() => setEditNo(null)} className="iconLink">
          <BsX className="icon" />
        </div>
        <h2>Details</h2>
        {!readOnly ? (
          <button
            className="addButton"
            style={{ margin: "0 10px" }}
            onClick={remove}
          >
            Remove
          </button>
        ) : (
          <div className="icon" />
        )}
      </nav>
      <div className="contentBody" style={{ maxHeight: "90%" }}>
        <form
          className="content"
          onSubmit={(e) => {
            e.preventDefault();
            if (!readOnly) {
              if (selectedProducts[editIndex].billQty < 1) {
                remove();
              }
              let filteredSerialNo = serialNo.filter(
                (el) => el !== "" && el != null
              );
              let arr = selectedProducts.slice();
              arr[editIndex].serialNo =
                filteredSerialNo.length > 0 ? filteredSerialNo : undefined;
              setSelectedProducts(arr);
            }
            setEditNo(null);
          }}
        >
          <AppForm readOnly name="Product Name" value={productData?.name} />
          <AppForm readOnly name="In Stock" value={productData?.qty} />
          <AppForm readOnly name="Details" value={productData?.info} />
          <AppForm
            readOnly={readOnly}
            type="number"
            name="Added Quantity"
            placeholder="0"
            value={selectedProducts[editIndex].billQty}
            setValue={(value) => {
              if (value <= productData?.qty) {
                let arr = selectedProducts.slice();
                arr[editIndex].billQty = value;
                setSelectedProducts(arr);
              }
            }}
          />
          <div
            className={
              serialNo.length > 1
                ? readOnly
                  ? "appForm numbers readOnly"
                  : "appForm numbers"
                : "appForm"
            }
          >
            <label>Serial No.</label>
            {serialNo.map((value, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <input
                  readOnly={readOnly}
                  style={{ flex: 1 }}
                  value={value}
                  onChange={(e) => {
                    let arr = serialNo.slice();
                    arr[index] = e.target.value;
                    setSerialNo(arr);
                  }}
                />
                {serialNo.length > 1 && !readOnly && (
                  <div
                    onClick={() => serialSplice(index)}
                    style={{ display: "flex" }}
                  >
                    <BsX style={{ margin: "auto" }} />
                  </div>
                )}
              </div>
            ))}
            {!readOnly && (
              <div onClick={handleAddMoreSerial} className="addButton">
                Add more
              </div>
            )}
          </div>
          <GreenButton name="Done" classN="greenButton form" />
        </form>
      </div>
    </div>
  );
}

export default EditPopUp;
