import React, { useEffect, useState } from "react";

function Address({ setAddress = () => {}, address }) {
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [pincode, setPincode] = useState("");
  const [landmark, setLandmark] = useState("");

  useEffect(() => {
    setAddress({
      state: state,
      city: city,
      street: street,
      pincode: pincode,
      landmark: landmark,
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, city, street, pincode, landmark]);

  return (
    <div className="appForm address">
      <label>Address</label>
      <div>
        <input
          readOnly={address ? true : false}
          placeholder="State"
          autoComplete="new-password"
          value={address ? address.state : state}
          onChange={(e) => setState(e.target.value)}
        />
        <input
          readOnly={address ? true : false}
          placeholder="City"
          autoComplete="new-password"
          value={address ? address.city : city}
          onChange={(e) => setCity(e.target.value)}
        />
        <input
          readOnly={address ? true : false}
          placeholder="Street"
          autoComplete="new-password"
          value={address ? address.street : street}
          onChange={(e) => setStreet(e.target.value)}
        />
        <input
          readOnly={address ? true : false}
          placeholder="Zip/Pincode"
          autoComplete="new-password"
          value={address ? address.pincode : pincode}
          onChange={(e) => setPincode(e.target.value)}
        />
        <input
          readOnly={address ? true : false}
          placeholder="Landmark"
          autoComplete="new-password"
          value={address ? address.landmark : landmark}
          onChange={(e) => setLandmark(e.target.value)}
        />
      </div>
    </div>
  );
}

export default Address;
