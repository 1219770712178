import NumberFormat from "react-number-format";
const withValueLimit = ({ floatValue }) => (floatValue || 0) <= 100;

function AppForm({
  placeholder,
  name,
  value = "",
  type,
  min,
  setValue = () => {},
  readOnly,
  useNumberFormat,
  format,
  prefix,
  isPercent,
  thousandsGroupStyle, //"lakh"
  thousandSeparator,
  style,
  required,
}) {
  return (
    <div
      className={
        name ? (readOnly ? "appForm readOnly" : "appForm") : "appForm input"
      }
      style={style}
    >
      {name && <label>{name}</label>}
      {useNumberFormat ? (
        <NumberFormat
          min={min}
          isAllowed={isPercent ? withValueLimit : undefined}
          required={required}
          readOnly={readOnly}
          placeholder={placeholder}
          autoComplete="new-password"
          value={value}
          format={format}
          onValueChange={({ value }) => setValue(value)}
          prefix={prefix}
          suffix={isPercent ? "%" : undefined}
          thousandsGroupStyle={thousandsGroupStyle}
          thousandSeparator={thousandSeparator}
        />
      ) : (
        <input
          min={min}
          required={required}
          readOnly={readOnly}
          placeholder={placeholder}
          type={type}
          autoComplete="new-password"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
    </div>
  );
}

export default AppForm;
