import React, { useState } from "react";
import { Link } from "react-router-dom";
import LogInWith from "../../components/LogInWith";
import { useAuth } from "../../providers/authProvider";
import { ReactComponent as FullLogo } from "../../assets/fullLogo.svg";
import GreenButton from "../../components/GreenButton";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const { signinEmailPassword } = useAuth();

  return (
    <form
      className="signAuthBody"
      onSubmit={(e) => {
        e.preventDefault();
        signinEmailPassword(email, pass);
      }}
    >
      <FullLogo className="fullLogo" />
      <h2>Sign In</h2>
      <div>
        <input
          type="email"
          required
          placeholder="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <input
          type="password"
          required
          placeholder="Password"
          value={pass}
          onChange={(event) => setPass(event.target.value)}
        />
      </div>
      <GreenButton name="Sign In" />
      <Link to="/signUp" style={{ textDecorationLine: "none" }}>
        Sign Up instead?
      </Link>
      <LogInWith />
    </form>
  );
};

export default SignIn;
